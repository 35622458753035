import React, { useEffect, useState } from 'react'
import useTableSorting from '../../Hooks/useTableSorting';
import Loading from '../../Component/Loading';
import { Button, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableRow, } from '@mui/material';
import PaginationCom from '../../Component/pagination';
import { toast } from 'react-toastify';
import { EDIT_LIFE_STYLE_HABIT, LIFE_STYLE_HABIT_LIST } from '../../Repository/LifeStyleHabitRepo';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { BiArchiveIn } from 'react-icons/bi';
import Dialogs from '../../Component/Dialog';
import { dailogAllMessage, permissionMessage } from '../../Constants/Constants';
import { getlocalStorage, intialRole } from '../../utils';
import NoRecordFound from '../../Component/NoRecordFound';
const Index = () => {
    const [open, setOpen] = useState(false);
    const [DeletedData, setDeletedData] = useState({});
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10, searchPage: null });
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [Filter, setFilter] = useState({
        archive: true,
        disable_button: true,
        business_category: ""
    })
    const [loading, setLoading] = useState(false)
    const [lifeStyleList, setLifeStyleList] = useState([])
    const naviget = useNavigate()
    const userData = getlocalStorage("userInfodata")
    const headCells = [
        { id: "#", numeric: false, disablePadding: true, label: "#", isShorted: false },
        { id: "lifeStyleName", numeric: true, disablePadding: false, label: "Lifestyle habit Name", isShorted: true },
        { id: "Active", numeric: true, disablePadding: false, label: "Status", showLeft: true, isShorted: false },
    ];
    const getLifeStyleHabitDetails = async () => {
        setLoading(true)
        const { page, rowsPerPage, searchPage } = controller;
        const { archive, business_category } = Filter;
        const keyword = business_category.trim();
        const payload = {
            keyword: (searchPage && keyword) ? keyword : undefined,
            page: page,
            count: rowsPerPage,
            activeStatus: archive,
            userId: userData?.userId
        };
        try {
            let response = await LIFE_STYLE_HABIT_LIST(payload)
            setLifeStyleList(response?.data)
            setPaginationTotalCount(response?.totalCount)
        } catch (error) {
            if (error.message !== "No lifestyle found") toast.error(error.message)
            setPaginationTotalCount(0)
            setLifeStyleList([])
        }
        finally {
            setLoading(false)
        }
    }


    const handleClose = async (event, data) => {
        if (event === "YES") {
            setLoading(true)
            let payload = {
                lifeStyleId: [data?.lifeStyleId],
                activeStatus: !data?.activeStatus
            }
            try {
                let response = await EDIT_LIFE_STYLE_HABIT(payload)
                toast.success(response.message)
                setLoading(false)
                setOpen(false)
                if (lifeStyleList?.length === 1) {
                    setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
                } else getLifeStyleHabitDetails()
            } catch (error) {
                toast.error(error.message)
                setOpen(false)
                setLoading(false)
            }

        } else {
            setOpen(!open)
        }
    }

    useEffect(() => {
        getLifeStyleHabitDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])
    const handleChangeFilter = (event) => {
        const { name, value } = event.target
        setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
    }
    const reset = () => {
        setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
        setFilter({
            archive: true,
            disable_button: true,
            business_category: ""
        })
    }
    const HandleSearchList = () => {
        setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
    }
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    const editArchivePermission = (!intialRole({ permission: "Lifestyle Habits", userData: userData, section: 'AbletoEditLifestyleHabits' }) && !intialRole({ permission: "Lifestyle Habits", userData: userData, section: 'AbletoActive/In-ActiveLifestyleHabits' }))
    if (editArchivePermission) {
        headCells.pop()
    }
    return (
        <>
            {loading && <Loading />}
            <div className="row">
                <div className="col-12 ">
                    <h3 className='PAGE-NAME'>Lifestyle Habit</h3>
                </div>
            </div>
            <div className="container-fluid">
                <section className='d-flex justify-content-between row'>
                    <div className='col-xxl-6 col-xl-9 col-sm-8 px-0'>
                        {
                            intialRole({ permission: "Lifestyle Habits", userData: userData, section: 'AbletoView/ListLifestyleHabits' }) ?
                                <div className="row">
                                    <div className="col-lg-6 col-sm-6 col-md-6 ">
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            className='w-100'
                                            size='small'
                                            name='archive'
                                            value={Filter?.archive}
                                            onChange={handleChangeFilter}
                                        >
                                            <MenuItem value={true}>Unarchive</MenuItem>
                                            <MenuItem value={false}>Archive</MenuItem>
                                        </Select>
                                    </div>
                                    {/* <div className="col-4">
                                <TextField id="outlined-basic" name='business_category' value={Filter?.business_category} onChange={handleChangeFilter} placeholder='Search habits.. ' variant="outlined" className='w-100' size='small' />
                            </div> */}
                                    <div className="mt-3 mt-sm-0 col-lg-6 col-md-5 col-sm-5 d-flex justify-content-sm-start  justify-content-end">
                                        <Button variant="contained" disabled={Filter?.disable_button} className='me-2 mui_button_color' onClick={HandleSearchList}>search</Button>
                                        <Button variant="contained" disabled={Filter?.disable_button} className='mui_button_color' onClick={reset}>reset</Button>
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                    {
                        intialRole({ permission: "Lifestyle Habits", userData: userData, section: 'AbletoaddLifestyleHabits' }) ?
                            <div className='col-xxl-2 col-xl-3 col-sm-4 d-flex justify-content-end pe-0 mt-3 mt-sm-0 '>
                                <Button variant="contained" className='mui_button_color' startIcon={<AddIcon />} onClick={() => naviget("add")}>
                                    Add
                                </Button>
                            </div>
                            : null
                    }
                </section>
                {
                    intialRole({ permission: "Lifestyle Habits", userData: userData, section: 'AbletoView/ListLifestyleHabits' }) ?
                        <>
                            <div className="row">
                                {
                                    lifeStyleList?.length === 0 && <div className="col-sm-12 mt-3 text-center " >
                                        <NoRecordFound />
                                    </div>
                                }
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <TableContainer className=" mt-4"  >
                                        <Table
                                            id="table_mui"
                                            aria-labelledby="tableTitle"
                                            size={"medium"}
                                            aria-label="enhanced table">
                                            {
                                                <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={lifeStyleList.length} />
                                            }
                                            <TableBody>
                                                {stableSort(lifeStyleList, getComparator(order, orderBy)).map(
                                                    (row, index) => {
                                                        const labelId = `enhanced-table-checkbox-${index}`;
                                                        return <React.Fragment key={row?.lifeStyleId || index}>
                                                            <TableRow id="hover_tr">
                                                                <TableCell className="ps-3" component="th" id={labelId} scope="row" padding="none" >
                                                                    {controller?.rowsPerPage * (controller?.page - 1) + index + 1}
                                                                </TableCell>
                                                                <TableCell id={labelId} align="left" >{row?.lifeStyleName}</TableCell>
                                                                {
                                                                    editArchivePermission ? null :
                                                                        <TableCell id={labelId} align="right" >
                                                                            {
                                                                                intialRole({ permission: "Lifestyle Habits", userData: userData, section: 'AbletoEditLifestyleHabits' }) ?
                                                                                    row?.activeStatus === true && <svg onClick={() => naviget(`edit/${row.lifeStyleId}`)} style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                                                                                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                                                                    </svg>
                                                                                    : null
                                                                            }
                                                                            {
                                                                                intialRole({ permission: "Lifestyle Habits", userData: userData, section: 'AbletoActive/In-ActiveLifestyleHabits' }) ?
                                                                                    <BiArchiveIn title={`${row?.archive === false ? "Archive" : "Unarchive"}`} className="all_react_icons_color ms-2 " onClick={() => { setDeletedData(row); setOpen(!open) }} />
                                                                                    : null
                                                                            }

                                                                        </TableCell>
                                                                }
                                                            </TableRow>
                                                        </React.Fragment>
                                                    }
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                            <section className='w-100 d-flex justify-content-end'>
                                {
                                    paginationTotalCount > 10 && <PaginationCom count={paginationTotalCount} controller={controller} setController={setController} />
                                }
                            </section>
                        </>
                        : <div className='d-flex justify-content-center mt-5 me-5'><h5>{permissionMessage}</h5></div>
                }
            </div>
            <Dialogs handleClose={handleClose} title={!DeletedData?.activeStatus ? dailogAllMessage?.habit?.UnArchive : dailogAllMessage?.habit?.Archive} open={open} data={DeletedData} />
        </>
    );
}

export default Index
