import { useNavigate } from 'react-router-dom';
const useBroserNotification = () => {
    const navigate = useNavigate()
    const handleCleckNotification = (data) => {
        const notification = new Notification(data?.message);
        notification.onclick = () => {
             navigate(data?.routes)
        };
    }
    const showNotification = (data) => {
        if ('Notification' in window) {
            if (Notification.permission === 'granted') {
                handleCleckNotification(data)
            } else if (Notification.permission !== 'denied') {
               
                Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                       handleCleckNotification(data)
                    }
                });
            }
        }
    };
    return {
        showNotification
    }
}

export default useBroserNotification