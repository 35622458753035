import { toast } from "react-toastify";
import { UPLOAD_FILE } from "../Repository/CommonRepo";
import { fileTypes } from "../Constants/Constants";
const useUploadImage = (type, setLoader, setImgUrl) => {
    const UploadImage = async (event, FileRef) => {
        const fileData = event.target.files[0];
        const fd = new FormData()
        if (fileData?.size / 1024 / 1024 > 10) return toast.warn("Please upload image less than 10 MB");
        if (!fileTypes.Img_Type.includes(fileData?.type)) return toast.warn("Only image format allowed");
        if (fileData?.size / 1024 / 1024 < 10 && fileTypes.Img_Type.includes(fileData?.type)) {
            fd.append('type', type)
            fd.append('file', fileData)
            setLoader(true)
            try {
                let response = await UPLOAD_FILE(fd)
                setImgUrl(response.data.db_name)
            } catch (error) {
                toast.error(error.message)
                setImgUrl(null)
            } finally {
                FileRef.current.value = null;
                setLoader(false)
            }
        }
    };
    return {
        UploadImage
    }
}
export default useUploadImage