import { useFormik } from 'formik'
import  { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { CreateCalendarFormValidation } from '../../../Validation/Validation'
import { EDIT_VACCINATION_FORM, VACCINATION_FORM_LIST } from '../../../Repository/VaccinationRepo'
import { toast } from 'react-toastify'
import { fileTypes, RoleTitle } from '../../../Constants/Constants'
import { DELETE_FILE, UPLOAD_FILE } from '../../../Repository/CommonRepo'
import { getlocalStorage } from '../../../utils'

const useVaccinationFormUpdate = () => {
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()
    const { id, from_id } = useParams()
    const { state } = useLocation()
    const FileRef = useRef()
    const userData = getlocalStorage("userInfodata")
    const getVaccinationFormDetails = async () => {
        const payload = {
            // vaccinationCategoryId: id,
            vaccinationFormId: from_id,
            // activeStatus: true
        };
        setLoader(true)
        try {
            let response = await VACCINATION_FORM_LIST(payload)
            formik.setFieldValue("vaccinationFormName", response?.data?.formName)
            formik.setFieldValue("calendarArray", response?.data?.vaccinationFormFieldArray)
            setLoader(false)
        } catch (error) {
            toast.error(error.message)
            setLoader(false)
        }
    }
    const formik = useFormik({
        validationSchema: CreateCalendarFormValidation,
        initialValues: {
            calendarArray: [],
            vaccinationFormName: ""
        },
        onSubmit: async (values) => {
            let payload = {
                categoryId: id,
                vaccinationFormId: from_id,
                form: values?.calendarArray,
                vaccinationFormName: values?.vaccinationFormName,
                [userData?.rolesAndPermission?.roleName === RoleTitle?.SUPER_ADMIN ? "superAdminId" : "staffId"]: userData?.userId,
                roleTitle: userData?.rolesAndPermission?.roleName 
            }
            setLoader(true)
            try {
                let response = await EDIT_VACCINATION_FORM(payload)
                toast.success(response.message)
                setLoader(false)
                navigate(`/layout/vaccination/${id}`, { state: state })
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        }
    })

    const UploadImage = async (e, index) => {
        if (!e) return
        const fd = new FormData()
        if (e.size / 1024 / 1024 > 10) return toast.warn('Please upload file less than 10 MB')
        if (!fileTypes.Img_Type.includes(e?.type)) return toast.warn('Only image format allowed')
        if (e?.size / 1024 / 1024 < 10 && fileTypes.Img_Type.includes(e?.type)) {
            fd.append('type', "VACCINATIONFORM")
            fd.append('file', e)
            setLoader(true)
            try {
                let response = await UPLOAD_FILE(fd)
                formik.setFieldValue(`calendarArray.${index}.vaccinationSerialNumber`, response?.data?.db_name)
            } catch (error) {
                toast.error(error.message)
            } finally {
                setLoader(false)
                FileRef.current.value = null;
            }
        }
    };
    const deleteFileFun = async (fileName, index,FormdId) => {
        let payload = { fileName: fileName, type: "VACCINATIONFORM", id: FormdId }
        setLoader(true)
        try {
            let response = await DELETE_FILE(payload)
            formik.setFieldValue(`calendarArray.${index}.vaccinationSerialNumber`, "")
            toast.success(response?.data?.message)
        } catch (error) {
            toast.error(error.message)
        } finally {
            setLoader(false)
        }
    }
    useEffect(() => {
        getVaccinationFormDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return {
        formik,
        loader,
        FileRef,
        UploadImage,
        deleteFileFun,
    }
}
export default useVaccinationFormUpdate
