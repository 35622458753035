import './App.css';
import { Routes, Route } from 'react-router-dom';
import Layout from './layout/Layout';
import Login from './Pages/User/login';
import React, { useEffect, useState } from 'react';
import CreatePIN from './Pages/AcceptInvitation/CreatePIN';
import RejectInvitation from './Pages/RejectInvitation/RejectInvitation';
import { GET_ROLE_PERMISSIONS_DETAILS } from './Repository/Roles&PermissionRepo';
import Page404 from './Pages/404page';
import { getlocalStorage } from './utils';
export const UserInfo = React.createContext();
function App() {
  const [notificationcount, setNotificationcount] = useState(0)
  const userData = getlocalStorage("userInfodata")
  
  useEffect(() => {
    getPermissionsdetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getPermissionsdetails = async () => {
    const payload = {
      roleId: userData?.roleTitle
    };
    try {
      let response = await GET_ROLE_PERMISSIONS_DETAILS(payload)
      let data = JSON.parse(localStorage.getItem('userInfodata'))
      let updateData = { ...data }
      updateData.rolesAndPermission.permissionArray = response?.data?.permissionArray
      localStorage.setItem("userInfodata", JSON.stringify(updateData || []))
    } catch (error) {
    }
  }
  return (
    <>
      <UserInfo.Provider value={{ notificationcount, setNotificationcount }}>
        <Routes>
          <Route path="acceptInvitation/:token" element={<CreatePIN />} />
          <Route path="declineInvitation/:token" element={<RejectInvitation />} />
          <Route path="acceptInvitation/:token/declineInvitation" element={<RejectInvitation />} />
          <Route path='/' element={<Login />} />
          <Route path='*' element={<Page404 />} />
          <Route path='/layout/*' element={<Layout />} />
        </Routes>
      </UserInfo.Provider>
    </>
  );
}

export default App;
