import React, { useEffect, useRef, useState } from 'react'
import PhoneNumber from '../../Component/PhoneInput'
import { useFormik } from 'formik'
import { Errors } from '../../Component/ErrorShow'
import { TextInput } from '../../Component/Inputs'
import { UserImageFilePath } from '../../services/Api'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import DeleteIcons from '../../Component/DeleteIcons'
import useUploadImage from '../../Hooks/useUploadImage'
import Loader from '../../Component/Loading'
import { Button } from '@mui/material'
import { PROVIDER_LIST } from '../../Repository/ProviderRepo'
import { toast } from 'react-toastify'
import { getlocalStorage } from '../../utils'
import { RoleTitle } from '../../Constants/Constants'
import { pattern, updateProfileValidation } from '../../Validation/Validation'
import { UPDATE_TEAM } from '../../Repository/TeamManagementRepo'
const Profile = () => {
  const [Loading, setLoading] = useState(false)
  const [imgUrl, setImgUrl] = useState(null)
  const userData = getlocalStorage("userInfodata")
  // const { UploadImage } = useUploadImage(userData?.rolesAndPermission?.roleName===RoleTitle?.SUPER_ADMIN ? "PROVIDER" : "TEAM", setLoading, setImgUrl)
  const { UploadImage } = useUploadImage("PROVIDER", setLoading, setImgUrl)

  const FileRef = useRef()
  const getProfileDetails = async () => {
    setLoading(true)
    let payload = {
      providerId: userData?.userId,
      rolesToList: [RoleTitle?.SUPER_ADMIN],
      getStaffList: false
    }
    try {
      let response = await PROVIDER_LIST(payload)
      formik.setValues(response.data)
      setImgUrl(response.data?.image)
      let updateUserData = { ...userData }
      updateUserData.lastName = response.data?.lastName
      updateUserData.firstName = response.data?.firstName
      updateUserData.image = response.data?.image
      localStorage.setItem("userInfodata", JSON.stringify(updateUserData))
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }


  const formik = useFormik({
    initialValues: {
      mobile: "",
      firstName: "",
      lastName: "",
      email: ""
    },
    validationSchema: updateProfileValidation,
    validateOnChange: true,
    validateOnBlur: true,
    validate: (values) => {
      const errors = {};
      if (values?.mobile?.length > 0 && (values?.mobile.match(pattern?.PHONE_NO))) {
        delete errors.mobile
      } else {
        errors.mobile = "Please enter a valid mobile number ";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setLoading(true)
      const { deletedStatus, invitationStatus, title, userId, activeStatus, ...restValues } = values
      let payload = {
        ...restValues,
        authId: userData?.authId,
        userId: userData?.userId,
        image: imgUrl,
      }
      try {
        let response = await UPDATE_TEAM(payload)
        toast.success(response.message)
        setLoading(false)


        setTimeout(() => {
          window.location.reload(false)
        }, 1000)
      } catch (error) {
        toast.error(error.message)
        setLoading(false)
      }
    },
  })

  const handleChangeInput = (event) => {
    const { name, value } = event.target
    formik.setFieldValue(name, value)
  }
  useEffect(() => {
    getProfileDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {Loading && <Loader />}
      <section >
        <h3 className='PAGE-NAME'> Profile</h3>
      </section>
      <div className="container-fluid shadow py-3">
        <form className="row g-3 mt-4" onSubmit={formik.handleSubmit} >
          <div className="col-sm-12 m-auto">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-3 col-xl-2 col-sm-5 col-md-3  col-7">
                <div className="bodrerer mb-4 d-flex justify-content-center align-items-center" style={{ border: `${!imgUrl ? "2px dashed #aaaaaa" : "none"}` }}>
                  <label htmlFor="inputTag">
                    {
                      !imgUrl && <AiOutlineCloudUpload className="fs-4 " />
                    }

                    {imgUrl && <img src={UserImageFilePath + imgUrl} alt="" />}
                    <input ref={FileRef} id="inputTag" type="file" name="file" onChange={(e) => UploadImage(e, FileRef)} />
                    {
                      !imgUrl && <>
                        <br /> Upload an Image<br />
                        <span id="imageName"></span>
                      </>
                    }
                  </label>
                  {imgUrl && <DeleteIcons setLoading={setLoading} setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "PROVIDER", id: null }} isDeleted={true} />}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 mb-2 mt-0">
            <label className="form-label mb-0">First Name<span className='Star_Mark'>*</span></label>
            <TextInput
              handleChange={handleChangeInput}
              maxLength={50}
              name="firstName"
              value={formik.values.firstName}
              type="text"
              size='small'
            />
            <Errors formikfun={formik} name={"firstName"} />
          </div>
          <div className="col-sm-6 mb-2 mt-0">
            <label className="form-label mb-0">Last Name<span className='Star_Mark'>*</span></label>
            <TextInput
              handleChange={handleChangeInput}
              maxLength={50}
              name="lastName"
              value={formik.values.lastName}
              type="text"
              size='small'
            />
            <Errors formikfun={formik} name={"lastName"} />
          </div>
          <div className="col-sm-6 mb-2 ms-0 mt-0 mobile_number_input_box">
            <label className="form-label mb-0">Mobile Number<span className='Star_Mark'>*</span></label>
            <div className="input_group_mobile mb-0">
              <PhoneNumber
                width={false}
                Require={false}
                Name="mobile"
                HandleChange={handleChangeInput}
                Value={formik.values.mobile}
                disabledInput={true}
              />
            </div>
            <Errors formikfun={formik} name={"mobile"} />
          </div>
          <div className="col-sm-6 mb-2 mt-0">
            <label className="form-label mb-0">Email Address<span className='Star_Mark'>*</span></label>
            <TextInput
              handleChange={handleChangeInput}
              maxLength={50}
              name="email"
              value={formik.values.email}
              type="email"
              size='small'
              disable={true}
            />
            <Errors formikfun={formik} name={"email"} />
          </div>
          <div className="col-12 text-end">
            <Button variant="contained" type="submit" className="my-3 mui_button_color">update</Button>
          </div>
        </form>
      </div>
    </>
  )
}

export default Profile